@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
*{
  font-family: 'Roboto', sans-serif;
}

body {
  color: red;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root > div > div:first-of-type {
  transform: unset !important;
}

*{
  margin: 0;
  padding: 0;
}

#root{
  width: 100vw;
  height: 100vh;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


