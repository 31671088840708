*{
  margin: 0;
  padding: 0;
}

#root{
  width: 100vw;
  height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

